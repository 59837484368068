import {
  useEffect,
  useState,
  memo,
  useCallback,
  PointerEvent,
  useRef,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Board from "@/components/board/Board";
import { FiltersContainer, HomeContainer, LoadingBox } from "./HomeStyles";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { TreeItem2 } from "@mui/x-tree-view/TreeItem2";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { migrationRoutes } from "@/routes/Routes";
import { migrationAPI } from "@/api/migrationAPI";
import { useSessionContext } from "@/contexts/SessionContext";
import {
  setCurrentIdentifiers,
  setCurrentProjectDetails,
} from "@/store/coreSlice";
import { Label, Span } from "@/components/text/Text";
import { clearMigrationSession } from "@/store/migrationSessionSlice";
import { Button } from "@/components/button/Button";
import Badge from "@/components/badge/Badge";
import {
  CloseIcon,
  CompressIcon,
  ExpandIcon,
  GearIcon,
} from "@/components/icons/Icons";
import CircularProgress from "@mui/material/CircularProgress";
import ModalLayout from "@/components/modal/ModalLayout";
import { ModalContainer } from "@/components/modal/ModalStyle";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  IClientSummary,
  IProjectIdentifiers,
  IProjectSummary,
} from "@/interfaces/types";
import TextField from "@mui/material/TextField";
import { styled, alpha } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";

const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
  color:
    theme.palette.mode === "light"
      ? theme.palette.grey[800]
      : theme.palette.grey[200],

  [`& .${treeItemClasses.content}`]: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),
    [`& .${treeItemClasses.label}`]: {
      fontSize: "0.8rem",
      fontWeight: 500,
    },
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

interface IHome {}



const Home: React.FC<IHome> = memo(() => {
  const navigate = useNavigate();
  const projectRef = useRef<HTMLLIElement | null>(null);
  const dispatch = useDispatch();
  const { session } = useSessionContext();
  const { accessToken } = session.data;
  const { currentIdentifiers, currentProjectDetails } = useSelector(
    (state: RootState) => state.core
  );
  const [isLoading, setIsLoading] = useState(false);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [filter, setFilter] = useState<
    | "All"
    | "Ready"
    | "InProgress"
    | "NotReady"
    | "ClientComplete"
    | "PartnerComplete"
    | "Blocked"
    | "MigratedOnly"
    //@ts-ignore
  >(localStorage.getItem("viewListFilter") ?? "All");
  const filtersList: Array<{
    name:
      | "All"
      // | "Ready"
      // | "InProgress"
      // | "NotReady"
      // | "ClientComplete"
      // | "PartnerComplete"
      // | "Blocked";
      | "MigratedOnly";
    text: string;
  }> = [
    { name: "All", text: "All projects" },
    // { name: "Ready", text: "Ready for migration" },
    // { name: "InProgress", text: "Migration in progress" },
    // { name: "ClientComplete", text: "Fully migrated Clients" },
    // { name: "PartnerComplete", text: "Fully migrated Partners" },
    // { name: "NotReady", text: "Pending" },
    // { name: "Blocked", text: "Blocked" },
    { name: "MigratedOnly", text: "Migrated (but still active in WUD)" },
  ];

  const ownerStates = [
    { name: "Unknown", color: "var(--grey)" },
    { name: "NoMigrationChurn", color: "var(--grey)" },
    { name: "NoMigrationBlocked", color: "var(--accent-02-light)" },
    { name: "SelfMigration", color: "var(--green-light)" },
    { name: "AssistedSelfMigration", color: "var(--import)" },
    { name: "FullMigration", color: "var(--import)" },
  ];
  const projectStates = [
    { name: "NotStarted", color: "var(--warning-light)" },
    { name: "InProgress", color: "var(--import)" },
    { name: "Blocked", color: "var(--accent-02-light)" },
    { name: "Migrated", color: "var(--green-light)" },
    { name: "Ignored", color: "var(--accent-02-light)" },
  ];

  const [partnerState, setPartnerState] = useState("");
  const [selectedPartner, setSelectedPartner] = useState<IProjectIdentifiers>();
  const [managePartnerVisible, setManagePartnerVisible] = useState(false);

  const [clientState, setClientState] = useState("");
  const [detached, setDetached] = useState(false);
  const [selectedClient, setSelectedClient] = useState<IClientSummary>();
  const [manageClientVisible, setManageClientVisible] = useState(false);

  const [projectState, setProjectState] = useState("");
  const [selectedProject, setSelectedProject] = useState<IProjectSummary>();
  const [manageProjectVisible, setManageProjectVisible] = useState(false);

  // comment to try pipeline
  const [updateMessage, setUpdateMessage] = useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setPartnerState(event.target.value as string);
  };

  const handleChangeClient = (event: SelectChangeEvent) => {
    setClientState(event.target.value as string);
  };

  const handleChangeProject = (event: SelectChangeEvent) => {
    setProjectState(event.target.value as string);
  };

  const handleExpandedItemsChange = useCallback(
    (event: React.SyntheticEvent, itemIds: string[]) => {
      setExpandedItems(itemIds);
    },
    []
  );

  const handleExpandClick = useCallback(() => {
    setExpandedItems((oldExpanded) =>
      oldExpanded.length === 0
        ? currentIdentifiers.map(
            (identifier) => `Partner${identifier.partnerUserGuid}`
          )
        : []
    );
  }, [currentIdentifiers]);

  const listProjectIdentifiers = useCallback(
    (
      selectedFilter:
        | "All"
        | "Ready"
        | "InProgress"
        | "NotReady"
        | "ClientComplete"
        | "PartnerComplete"
        | "Blocked"
        | "MigratedOnly"
    ) => {
      setIsLoading(true);
      migrationAPI
        .listProjectIdentifiers(accessToken, selectedFilter)
        .then((response: any) => {
          const { data, error } = response;
          if (!error) {
            dispatch(setCurrentIdentifiers(data?.value));
            setIsLoading(false);
          } else {
            dispatch(clearMigrationSession());
            navigate(migrationRoutes.login());
          }
        });
    },
    [accessToken, dispatch, navigate]
  );

  const updateOwnerPartnerState = (guid: string, state: string, progress: string) => {
    migrationAPI
      .updateOwnerPartnerState(guid, state, progress, accessToken)
      .then((response: any) => {
        const { data, error } = response;
        if (!error) {
          setManagePartnerVisible(false);
          setManageClientVisible(false);
        } else {
          dispatch(clearMigrationSession());
          navigate(migrationRoutes.login());
        }
      });
  };

  const updateOwnerClientState = (
    guid: string,
    state: string,
    progress: string,
    detachFromPartner: boolean
  ) => {
    migrationAPI
      .updateOwnerClientState(guid, state, progress, detachFromPartner, accessToken)
      .then((response: any) => {
        const { data, error } = response;
        if (!error) {
          setManagePartnerVisible(false);
          setManageClientVisible(false);
        } else {
          dispatch(clearMigrationSession());
          navigate(migrationRoutes.login());
        }
      });
  };

  const updateProjectState = (
    guid: string,
    progress: string,
    message: string
  ) => {
    migrationAPI
      .updateProjectState(guid, progress, message, accessToken)
      .then((response: any) => {
        const { data, error } = response;
        if (!error) {
          setManageProjectVisible(false);
        } else {
          dispatch(clearMigrationSession());
          navigate(migrationRoutes.login());
        }
      });
  };

  const getBadgeColor = (typeId: number): string => {
    const colors: Record<number, string> = {
      0: "var(--note)",
      1: "var(--medium)",
      2: "var(--high)",
      3: "var(--critical)",
    };
    return colors[typeId];
  };

  const onApplyFilter = (
    selectedFilter:
      | "All"
      | "Ready"
      | "InProgress"
      | "NotReady"
      | "ClientComplete"
      | "PartnerComplete"
      | "Blocked"
      | "MigratedOnly"
  ) => {
    setFilter(selectedFilter);
    localStorage.setItem("viewListFilter", selectedFilter);
    listProjectIdentifiers(selectedFilter);
  };

  const handleIconClickMappings = (event: React.MouseEvent) => {
    event.stopPropagation();
    setManagePartnerVisible(false);
    setManageClientVisible(false);
    setManageProjectVisible(false);
  };

  useEffect(() => {
    if (
      !managePartnerVisible &&
      (partnerState || clientState || projectState) &&
      !manageClientVisible &&
      !manageProjectVisible
    ) {
      setPartnerState("");
      setClientState("");
      setProjectState("");
      listProjectIdentifiers(filter);
    }
  }, [managePartnerVisible, manageClientVisible, manageProjectVisible]);

  useEffect(() => {
    listProjectIdentifiers(filter);
  }, []);

  useEffect(() => {
    if (currentProjectDetails && (!manageClientVisible && !managePartnerVisible && !manageProjectVisible)) {
      if (projectRef.current) {
        projectRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      const expandedItemsToSet = [
        `Partner${currentProjectDetails?.value?.partnerUserGuid}`,
        `Client${currentProjectDetails?.value?.clientUserGuid}`,
      ];
      setExpandedItems(expandedItemsToSet);
    }
  }, [
    currentProjectDetails,
    projectRef.current,
    filter,
    manageClientVisible,
    managePartnerVisible,
    manageProjectVisible,
  ]);

  return (
    <div
      style={{
        backgroundColor: "var(--background)",
        overflow:
          manageClientVisible || manageProjectVisible || managePartnerVisible
            ? "hidden"
            : "visible",
      }}
    >
      <Board>
        <FiltersContainer>
          {filtersList.map((item, index) => (
            <Button
              key={index}
              $buttonSize="small"
              $buttonType={filter === item.name ? "primary" : "complementary"}
              text={item.text}
              onClick={() => onApplyFilter(item.name)}
            />
          ))}
        </FiltersContainer>
        <HomeContainer className="HomeContainer">
          <div
            style={{ margin: "0 1rem 1rem 1rem", display: "flex", gap: "1rem" }}
          >
            <Button
              $buttonType="secondary"
              text={
                expandedItems.length === 0
                  ? "Expand partners"
                  : "Collapse partners"
              }
              icon={
                expandedItems.length === 0 ? <ExpandIcon /> : <CompressIcon />
              }
              onClick={handleExpandClick}
            />
          </div>
          <SimpleTreeView
            expandedItems={expandedItems}
            onExpandedItemsChange={handleExpandedItemsChange}
          >
            {currentIdentifiers.length > 0 ? (
              currentIdentifiers?.map((partner, index) => (
                <CustomTreeItem
                  key={index}
                  onClick={() => {
                    dispatch(setCurrentProjectDetails(null));
                  }}
                  itemId={`Partner${partner.partnerUserGuid}`}
                  label={
                    <Span
                      fontWeight="500"
                      style={{ display: "flex", gap: "0.5rem" }}
                    >
                      {partner.partnerUserGuid && (
                        <Button
                          text=""
                          $buttonType="primary"
                          $buttonSize="xsmall"
                          icon={<GearIcon />}
                          onClick={(event?: React.MouseEvent) => {
                            event?.stopPropagation();
                            setSelectedPartner(partner);
                            setPartnerState(partner.partnerProgress);
                            setManagePartnerVisible(true);
                          }}
                        />
                      )}
                      <Span
                        fontSize={0.75}
                        style={{
                          padding: "0.25rem",
                          backgroundColor: "var(--secondary-lighter)",
                          borderRadius: "0.25rem",
                        }}
                      >
                        {partner.summary}
                      </Span>

                      {partner.partnerState && (
                        <Span
                          fontSize={0.75}
                          style={{
                            padding: "0.25rem",
                            backgroundColor: ownerStates.find(
                              (state) => state.name === partner.partnerState
                            )?.color,
                            borderRadius: "0.25rem",
                          }}
                        >
                          {partner.partnerState}
                        </Span>
                      )}
                      {partner.partnerProgress && (partner.partnerState !== 'NoMigrationChurn' && partner.partnerState !== 'NoMigrationBlocked') && (
                        <Span
                          fontSize={0.75}
                          style={{
                            padding: "0.25rem",
                            backgroundColor: projectStates.find(
                              (state) => state.name === partner.partnerProgress
                            )?.color,
                            borderRadius: "0.25rem",
                          }}
                        >
                          {partner.partnerProgress}
                        </Span>
                      )}
                      {`${partner.partnerName}`}
                    </Span>
                  }
                >
                  {partner.clients?.map((client, index2) => (
                    <CustomTreeItem
                      key={index2}
                      onClick={() => {
                        dispatch(setCurrentProjectDetails(null));
                      }}
                      itemId={`Client${client.clientUserGuid}`}
                      label={
                        <Span
                          fontWeight="500"
                          style={{ display: "flex", gap: "0.5rem" }}
                        >
                          {client.clientUserGuid && (
                            <Button
                              text=""
                              $buttonType="primary"
                              $buttonSize="xsmall"
                              icon={<GearIcon />}
                              onClick={(event?: React.MouseEvent) => {
                                event?.stopPropagation();
                                setSelectedClient(client);
                                setClientState(client.clientProgress);
                                setDetached(client.partnerDetached);
                                setManageClientVisible(true);
                              }}
                            />
                          )}
                          <Span
                            fontSize={0.75}
                            style={{
                              padding: "0.25rem",
                              backgroundColor: "var(--secondary-lighter)",
                              borderRadius: "0.25rem",
                            }}
                          >
                            {client.summary}
                          </Span>

                          {client.clientState && (
                            <Span
                              fontSize={0.75}
                              style={{
                                padding: "0.25rem",
                                backgroundColor: ownerStates.find(
                                  (state) => state.name === client.clientState
                                )?.color,
                                borderRadius: "0.25rem",
                              }}
                            >
                              {client.clientState}
                            </Span>
                          )}
                          {client.clientProgress && (client.clientState !== 'NoMigrationChurn' && client.clientState !== 'NoMigrationBlocked') && (
                            <Span
                              fontSize={0.75}
                              style={{
                                padding: "0.25rem",
                                backgroundColor: projectStates.find(
                                  (state) =>
                                    state.name === client.clientProgress
                                )?.color,
                                borderRadius: "0.25rem",
                              }}
                            >
                              {client.clientProgress}
                            </Span>
                          )}
                          {client.clientName}
                        </Span>
                      }
                    >
                      {client.projects?.map((project, index3) => (
                        <CustomTreeItem
                          ref={
                            currentProjectDetails?.value?.guid ===
                            project.projectGuid
                              ? projectRef
                              : null
                          }
                          style={
                            currentProjectDetails?.value?.guid ===
                              project.projectGuid && projectRef.current !== null
                              ? { backgroundColor: "var(--warning-lighter2)" }
                              : {}
                          }
                          key={index3}
                          itemId={`Project${project.projectGuid}`}
                          label={
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.5rem",
                              }}
                            >
                              {project.projectGuid && (
                                <Button
                                  text=""
                                  $buttonType="primary"
                                  $buttonSize="xsmall"
                                  icon={<GearIcon />}
                                  onClick={(event?: React.MouseEvent) => {
                                    event?.stopPropagation();
                                    setSelectedProject(project);
                                    setProjectState(
                                      project.projectState.progress
                                    );
                                    setUpdateMessage(
                                      project.projectState.message
                                    );
                                    setManageProjectVisible(true);
                                  }}
                                />
                              )}
                              <Span
                                fontSize={0.75}
                                style={{
                                  padding: "0.25rem",
                                  backgroundColor: "var(--secondary-lighter)",
                                  borderRadius: "0.25rem",
                                }}
                              >
                                {project.summary}
                              </Span>
                              <Span fontWeight="500">
                                {project.projectName}
                              </Span>
                              {project.warnings?.severities?.length > 0 ? (
                                <>
                                  {[...project.warnings?.severities]
                                    .sort((a, b) => b.typeId - a.typeId)
                                    ?.map((severity, index) => (
                                      <Badge
                                        key={index}
                                        color="var(--white)"
                                        backgroundcolor={getBadgeColor(
                                          severity.typeId
                                        )}
                                        number={severity.count}
                                        text={`${severity.count} ${
                                          severity.count > 1
                                            ? "advisories"
                                            : "advisory"
                                        } of severity type: ${
                                          severity.type
                                        } in this project`}
                                      />
                                    ))}
                                </>
                              ) : (
                                <Badge
                                  color="var(--white)"
                                  backgroundcolor="var(--green)"
                                  number={0}
                                  text="There is no advisories in this project"
                                />
                              )}
                              <Span>
                                {project.warnings?.types.filter(
                                  (warning) =>
                                    warning.type ===
                                    "ProjectScheduledForMultipleDailyRuns"
                                ).length === 1 && (
                                  <Badge
                                    color="var(--white)"
                                    backgroundcolor="var(--sales)"
                                    textDisplay="SCH"
                                    text="This project has multiple schedules"
                                  />
                                )}
                              </Span>
                              {project.projectState?.progress && (
                                <Span
                                  fontSize={0.75}
                                  style={{
                                    padding: "0.25rem",
                                    backgroundColor: projectStates.find(
                                      (state) =>
                                        state.name ===
                                        project.projectState.progress
                                    )?.color,
                                    borderRadius: "0.25rem",
                                  }}
                                >
                                  {project.projectState?.progress}
                                </Span>
                              )}
                            </div>
                          }
                          onClick={() =>
                            navigate(
                              migrationRoutes.project(project.projectGuid)
                            )
                          }
                        />
                      ))}
                    </CustomTreeItem>
                  ))}
                </CustomTreeItem>
              ))
            ) : isLoading ? (
              <LoadingBox>
                <CircularProgress size={"5rem"} />
                <Label fontSize={2}>Preparing the data...</Label>
              </LoadingBox>
            ) : (
              <div style={{ padding: "1rem" }}>
                <Label fontWeight="500">No projects to show</Label>
              </div>
            )}
          </SimpleTreeView>
        </HomeContainer>
        {managePartnerVisible && (
          <ModalLayout onClick={handleIconClickMappings}>
            <ModalContainer
              style={{ padding: "1rem", maxWidth: "40%" }}
              onClick={(event: PointerEvent<HTMLDivElement>) =>
                event.stopPropagation()
              }
            >
              <div style={{ display: "flex", margin: 0 }}>
                <Label>Update partner state</Label>
              </div>
              <div style={{ maxWidth: "100%", display: "flex" }}>
                <Box sx={{ width: "50%", zIndex: 99999 }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Partner state
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={partnerState}
                      label="Partner state"
                      onChange={handleChange}
                      sx={{ textAlign: "left", color: "var(--main)" }}
                    >
                      {projectStates.map((state, index) => (
                        <MenuItem
                          key={index}
                          sx={{ color: "var(--main)" }}
                          value={state.name}
                        >
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: 0,
                }}
              >
                <Button
                  $buttonSize="small"
                  text="Update partner"
                  $buttonType="primary"
                  onClick={() =>
                    updateOwnerPartnerState(
                      selectedPartner?.partnerUserGuid ?? "",
                      "Unknown",
                      partnerState
                    )
                  }
                />
              </div>
            </ModalContainer>
          </ModalLayout>
        )}
        {manageClientVisible && (
          <ModalLayout onClick={handleIconClickMappings}>
            <ModalContainer
              style={{ padding: "1rem", maxWidth: "40%" }}
              onClick={(event: PointerEvent<HTMLDivElement>) =>
                event.stopPropagation()
              }
            >
              <div style={{ display: "flex", margin: 0 }}>
                <Label>Update client state</Label>
              </div>
              <div style={{ maxWidth: "100%", display: "flex" }}>
                <Box sx={{ width: "50%", zIndex: 99999 }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="client-simple-select-label">
                      Client state
                    </InputLabel>
                    <Select
                      labelId="client-simple-select-label"
                      id="client-simple-select"
                      value={clientState}
                      label="Client state"
                      onChange={handleChangeClient}
                      sx={{ textAlign: "left", color: "var(--main)" }}
                    >
                      {projectStates.map((state, index) => (
                        <MenuItem
                          key={index}
                          sx={{ color: "var(--main)" }}
                          value={state.name}
                        >
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <Label
                      fontWeight="500"
                      fontSize={0.75}
                      style={{ display: "flex", marginTop: "0.5rem" }}
                    >
                      <Checkbox
                        style={{ padding: "0.25rem" }}
                        size="small"
                        color="primary"
                        checked={detached}
                        onChange={(event) => setDetached(!detached)}
                        inputProps={{ "aria-label": "Detach from partner" }}
                      />
                      Detach from partner
                    </Label>
                  </FormControl>
                </Box>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: 0,
                }}
              >
                <Button
                  $buttonSize="small"
                  text="Update client"
                  $buttonType="primary"
                  onClick={() =>
                    updateOwnerClientState(
                      selectedClient?.clientUserGuid ?? "",
                      "Unknown",
                      clientState,
                      detached
                    )
                  }
                />
              </div>
            </ModalContainer>
          </ModalLayout>
        )}
        {manageProjectVisible && (
          <ModalLayout onClick={handleIconClickMappings}>
            <ModalContainer
              style={{
                padding: "1rem",
                maxWidth: "40%",
                minHeight: "15rem",
                display: "flex",
                justifyContent: "space-between",
              }}
              onClick={(event: PointerEvent<HTMLDivElement>) =>
                event.stopPropagation()
              }
            >
              <div style={{}}>
                <div
                  style={{
                    maxWidth: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Label style={{ textAlign: "left" }}>
                      Update project state
                    </Label>
                    <CloseIcon
                      cursor={"pointer"}
                      onClick={handleIconClickMappings}
                      $size={1}
                      hover={"var(--secondary)"}
                    />
                  </div>
                  <Box sx={{ width: "50%", zIndex: 99999 }}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="project-simple-select-label">
                        Project state
                      </InputLabel>
                      <Select
                        labelId="project-simple-select-label"
                        id="project-simple-select"
                        value={projectState}
                        label="Project state"
                        onChange={handleChangeProject}
                        sx={{ textAlign: "left", color: "var(--main)" }}
                      >
                        {projectStates.map((state, index) => (
                          <MenuItem
                            key={index}
                            sx={{ color: "var(--main)" }}
                            value={state.name}
                          >
                            {state.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <TextField
                    id="standard-basic"
                    label="Message"
                    variant="standard"
                    value={updateMessage}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setUpdateMessage(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: 0,
                }}
              >
                <Button
                  $buttonSize="small"
                  text="Update project"
                  $buttonType="primary"
                  onClick={() =>
                    updateProjectState(
                      selectedProject?.projectGuid ?? "",
                      projectState,
                      updateMessage
                    )
                  }
                />
              </div>
            </ModalContainer>
          </ModalLayout>
        )}
      </Board>
    </div>
  );
});

export default Home;
